<template>

<div class="text-left no-print">
  <v-menu ref="menu" v-model="active" top bottom offset-y min-width="300px" min-height="300px" :close-on-content-click="false" class="no-print">
    <template v-slot:activator="{ on: { click }, attrs }">
      <v-btn @click="getDados" :color="variables.colorSecondary" dark v-bind="attrs"> 
        {{ texto }}
      </v-btn>
    </template>

    <v-card class="card pb-5" v-if="!loading">
      <table class="table" v-if="resultInst">
        <thead>
          <tr>
            <!-- <th class="table-title text-center" colspan="3" scope="col"> {{ v[0].lottery }} </th> -->
          </tr>
        </thead>
          <tbody>
            <tr v-for="(prize, p) in resultInst[0]" :key="`${p}`" class="align-middle">
              <td class="table-premio"> {{ `${prize.number}º` }}  </td>
              <!-- Numero resultado -->
              <td  class="">
                <div class="d-flex d-row d-nowrap justify-content-start table-b-content">
                  <div class="table-b-ball d-flex justify-content-center align-items-center" v-for="(number, key) in prize.numberFormat" :key="`${number}-${p}-${key}-number-result`">
                    <span class=""> {{ number }} </span>
                  </div>
                </div>
              </td>
              <!-- Grupo -->
              <td v-if="prize.groupFormat">
                <div class="d-flex d-row d-nowrap justify-content-start table-b-content">
                  <div class="table-b-ball group d-flex justify-content-center align-items-center">
                    <span> {{ prize.groupFormat || '' }} </span>
                  </div>
                </div>
              </td>

              <td v-if="prize.group_description">
                <div class="d-flex d-row d-nowrap justify-content-start table-b-description">
                  {{ prize.group_description || '' }}
                </div>
              </td>
            </tr>
        </tbody>
      </table>  
      <table class="table" v-if="resultInst">
        <thead>
          <tr>
            <!-- <th class="table-title text-center" colspan="3" scope="col"> {{ v[0].lottery }} </th> -->
          </tr>
        </thead>
          <tbody>
            <tr v-for="(prize, p) in resultInst[1]" :key="`${k}-${p}`" class="align-middle">
              <td class="table-premio"> {{ `${prize.number === 100 ? 'SUP5' : 'SQL' }` }}  </td>
              <!-- Numero resultado -->
              <td  class="">
                <div class="d-flex d-row d-nowrap justify-content-start table-b-content">
                  <div class="table-b-ball d-flex justify-content-center align-items-center" v-for="(number, key) in prize.numberFormat" :key="`${number}-${p}-${k}-${key}-number-result`">
                    <span class=""> {{ number }} </span>
                  </div>
                </div>
              </td>
            </tr>
        </tbody>
      </table>
    </v-card>
    <div class="d-flex loader" v-else>
      <v-progress-circular  indeterminate color="primary" />
    </div>
  </v-menu>
</div>

</template>

<script>
// eslint-disable-next-line
import moment from 'moment'
import { mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
moment.locales('pt-br')
export default {
  name: 'FinanceiroTooltipLancamento',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    
    loading: {
      type: Boolean,
      defualt: false
    },

    texto: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    active: false
  }),

  mounted () {},
  computed: {
    ...mapGetters('ganhadores', ['resultInst']),
    variables: () => variables,
  },
  methods: {
    getDados () {
      this.active = true
      this.$emit('getResultados', this.item.id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/helpers/variables";
.loader {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  background: white;
}
.card {
  &-title {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 0;
  }
  &-sub {
    font-weight: normal;
    font-size: 12px;
    color: #9797ad !important;
  }
  &-t {
    color: #9797ad !important;
    &-title {
      color:#9797ad;
      font-size: 16px;
      font-weight: bold;
    }
    &-type {
      height: 25px;
    }
    span {
      color: #525252;
      font-size: 14px;
    }
  }
  &-links {
    display: block;
    width: 100%;
    background: #efefef;
    padding: 15px 0;
    font-size: 14px;
    &-text{
      color: #5076ff;
      span {
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          color: #708efc;
        }
      }
      span.ex:hover{
          color: red !important;
        
      }
    }
  }
}

 .table  {
   padding: 10px;
  &-title {
    background: $colorPrimary;
    color: white;
    font-size: 14px;
    font-weight: normal;
    outline: none;
    border-bottom: 0;
    border-radius: 10px 10px 0px 0px;
  }
  &-premio {
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    align-items: left;
  }
  &-b {
    &-ball, .group{
      width: 30px;
      height: 30px;
      margin-right: 5px;
      font-size: 12px;
      border: solid 0.7px 50%;
      color: $colorPrimary;
      border-radius: 50%;
      font-weight: bold;
      transition: all 0.3s;
      justify-content: center;
      align-items: center;
      border: 1px solid $colorPrimary;
      &.group{
        background: $colorPrimary;
        color: white;
      }
    }

    &-content {
      justify-content: center;
      padding: 0 10px;
    }

    &-description {
      font-size: 12px;
      font-weight: 700;
      text-align: right;
      justify-content: flex-end;
    }
  }
}

</style>